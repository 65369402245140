<template>
    <div>
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
        ></psi-page-title>
        <v-card class="mt-4">
            <v-card-text>
                <applicant-test-selector></applicant-test-selector>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "playground",
    components: {
        ApplicantTestSelector: () => import('@components/application/components/Apply/ApplicantTestSelector.vue')
    },
    props: {},
    data() {
        return {
            page: {
                title: "Applications Development Playground",
                icon: "mdi-apps",
            },
            breadcrumbs: [
                {
                    text: "Developing Application",
                    disabled: true,
                },
            ],
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>